import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import ProductDetail from "../../components/products/productDetail"
import ProductCatalog from "../../components/products/productCatalog"
import Toptitle from "../../components/toptitle"

const Panel = () => (
  <Layout>
    <SEO title="デザインパネル | 製品紹介" />
    <div>
      <section className="w-full lg:mr-0 lg:ml-auto lg:w-2/3">
        <Toptitle>Design Panel</Toptitle>
        <div className="w-full pt-32 lg:mt-16 lg:pt-0">
          <div className="w-full md:flex">
            <StaticImage
              src="../../images/products/panel.jpg"
              alt="デザインパネルの事例"
              className="w-full md:w-1/2"
            />
            <div className="w-full bg-secondery py-8 px-12 md:w-1/2">
              <h2 className="py-2 font-mincho text-2xl text-primary">
                デザインパネル
              </h2>
              <p className="font-gothic leading-loose">
                表面に凹凸がされており、照明を当てることで美しい陰影が表現できます。{" "}
                <br />
                空間のアクセントとして壁面に利用される他にもアートパネルとしても利用されます。
              </p>
            </div>
          </div>
        </div>
        <div className="mx-2 mt-32 grid grid-cols-2 gap-2 md:grid-cols-2 md:gap-4 lg:mx-0">
          <div className="border-b border-primarySupport pb-4">
            <StaticImage src="../../images/products/product/panel/01.jpg" />
            <ProductDetail name="ART PANEL 01" size="20×500×3000" />
          </div>
          <div className="border-b border-primarySupport pb-4">
            <StaticImage src="../../images/products/product/panel/03.jpg" />
            <ProductDetail name="ART PANEL 03" size="20×500×3000" />
          </div>
          {/* <div className="border-b border-primarySupport pb-4">
            <StaticImage src="../../images/products/product/panel/04.jpg" />
            <ProductDetail name="ART PANEL 04" size="20×500×3000" />
          </div> */}
          <div className="border-b border-primarySupport pb-4">
            <StaticImage src="../../images/products/product/panel/05.jpg" />
            <ProductDetail name="ART PANEL 05" size="20×500×3000" />
          </div>
          <div className="border-b border-primarySupport pb-4">
            <StaticImage src="../../images/products/product/panel/06.jpg" />
            <ProductDetail name="ART PANEL 06" size="15×500×3000" />
          </div>
          <div className="border-b border-primarySupport pb-4">
            <StaticImage src="../../images/products/product/panel/08.jpg" />
            <ProductDetail name="ART PANEL 08" size="15×500×3000" />
          </div>
          {/* <div className="border-b border-primarySupport pb-4">
            <StaticImage src="../../images/products/product/panel/09.jpg" />
            <ProductDetail name="ART PANEL 09" size="15×500×3000" />
          </div> */}
          <div className="border-b border-primarySupport pb-4">
            <StaticImage src="../../images/products/product/panel/p-01.jpg" />
            <ProductDetail name="ART PANEL PRESS 01" size="10×900×2400" />
          </div>
          <div className="border-b border-primarySupport pb-4">
            <StaticImage src="../../images/products/product/panel/p-02.jpg" />
            <ProductDetail name="ART PANEL PRESS 02" size="10×900×2400" />
          </div>
          <div className="border-b border-primarySupport pb-4">
            <StaticImage src="../../images/products/product/panel/p-03.jpg" />
            <ProductDetail name="ART PANEL PRESS 03" size="10×900×2400" />
          </div>
        </div>
        <ProductCatalog />
      </section>
    </div>
  </Layout>
)

export default Panel
